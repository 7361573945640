import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildConfigurationSampleWorkflowsUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';

export const loadConfigurationSampleWorkflows = (filters: FilterObject[]): Observable<any> => {
    const url = buildConfigurationSampleWorkflowsUrlWithFilters(filters);
    const obs = sendRetrieveRequest(url);
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const items = convertToCamel<any>(response.SampleWorkflow);
            return observableOf(items);
        }),
    );
};
